import { Box } from '@mui/material'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import HeroBanner from '../components/HeroBanner'
import DonationComponent from '../components/landing-page/DonationComponent'
import EndorsementSummary from '../components/landing-page/EndorsementSummary'
import Form from '../components/landing-page/Form'
import IntroComponent from '../components/landing-page/IntroComponent'
import IssueSummary from '../components/landing-page/IssueSummary'
import MapComponent from '../components/landing-page/MapComponent'
import PressClippings from '../components/landing-page/PressClippings'
import Layout from '../components/Layout'
import { withHelmet } from '../helpers/metadataHelpers'

export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  intro: { embed, useEmbed, header, subheader, text, intro_image: introImage, cta_text: ctaText },
  donationBlurb,
  platformBlurb,
  endorsementsBlurb,
  mapBlurb,
}) => (
  <Box>
  <HeroBanner image={image} title={title} subtitle={subtitle}>
    <Form/>
  </HeroBanner>
    <IntroComponent {...{ embed, useEmbed, introImage, header, subheader, text, ctaText }} />
    {donationBlurb.enabled && <DonationComponent {...donationBlurb} />}
    <IssueSummary {...platformBlurb} />
    {endorsementsBlurb.enabled && <EndorsementSummary {...endorsementsBlurb} />}
    <PressClippings />
    <MapComponent {...mapBlurb}/>
  </Box>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  intro: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        intro={frontmatter.intro}
        donationBlurb={frontmatter.donationblurb}
        platformBlurb={frontmatter.platformblurb}
        endorsementsBlurb={frontmatter.endorsementsblurb}
        mapBlurb={frontmatter.mapblurb}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default withHelmet(IndexPage)

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        pagename
        title
        subtitle
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 88) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro {
          header
          subheader
          text
          cta_text
          embed
          useEmbed
          intro_image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 88) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        donationblurb {
          enabled
          header
          text
        }
        platformblurb {
          enabled
          header
          text
          ctaText
        }
        mapblurb {
          header
          text
          neighborhoods {
            name
          }
        }
        endorsementsblurb {
          enabled
          header
          ctaText
          selectedEndorsements {
            name
            url
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300, fit: COVER, background: "rgba(0,0,0,0)", duotone: { highlight: "#ffffff", shadow: "#2e2e2e" }) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
