import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link as GastbyLink } from 'gatsby';
import React from 'react';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import Embed from '../shared/Embed';

const IntroComponent = ({ introImage, embed, useEmbed, subheader, header, text, ctaText }) => {
  return (
    <Container>
      <Grid container columnSpacing={{ sm: 6, md: 8 }} my={{ xs: 8, sm: 14}} direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6} mt={{ xs: 4, md: 0}} order={{ xs: 2, md: 0}}>
          {useEmbed && (
            <Embed embed={embed} />
          )}
          {!useEmbed && (
            <PreviewCompatibleImage imageInfo={{
              image: introImage,
              alt: 'some image',
            }} />
          )}
        </Grid>
        <Grid item xs={12} md={6} pl={{xs: 0, md: 5}} order={1}>
          <Box>
            <Typography component="h2" variant="h4" gutterBottom>
              {header}
            </Typography>
            <Typography paragraph={true} gutterBottom>
              {text}
            </Typography>
            <Button
              component={GastbyLink}
              to="/meet-huge"
              variant="contained"
              disableElevation>
                {ctaText}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default IntroComponent
