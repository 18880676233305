import { Link, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import SectionComponent from './SectionComponent'

import AppContext from '../AppContext'

const buttons = [
  { amount: 25, text: '$25' },
  { amount: 50, text: '$50' },
  { amount: 100, text: '$100' },
  { amount: 250, text: '$250' },
  { amount: 500, text: '$500' },
  { amount: 'other', text: 'Other' },
]

// const DonationComponent = ({ header, text, hidden }) => (
class DonationComponent extends React.Component {
  render() {
    const { header, text, hidden } = this.props;
    const { actblueurl } = this.context;

  return hidden ? null : (
    <SectionComponent
      header={header}
      headerAlign="center"
      headerVariant="h4"
      bgcolor="third.main"
      color="third.contrastText"
    >
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4}>
        <Typography color="white.main">{text}</Typography>
        </Grid>
        <Grid container item xs={12} sm={8} spacing={1}>
          {buttons.map(button => (
            <Grid item xs={4} key={button.amount}>
              <Button
                component={Link}
                target="_blank"
                href={`${actblueurl}?amount=${button.amount}`}
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                sx={{ color: "third.main" }}
                disableElevation
              >
                {button.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </SectionComponent>
  )}
}
// )

DonationComponent.contextType = AppContext

export default DonationComponent
