import { Box, Button, Grid, Link, useMediaQuery, useTheme } from '@mui/material';
import { graphql, Link as GastbyLink, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import SectionComponent from './SectionComponent';

function PressClippings({ data: { markdownRemark } }) {
  const { articles } = markdownRemark.frontmatter
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const articleLimit = isMobile ? 9 : 10 ;

  const filteredArticles = articles.filter(({ featured }) => featured).slice(0, articleLimit);

  return (
    <SectionComponent headerAlign="center" header="Press"
      button={
        <Button
          component={GastbyLink}
          to="/press"
          variant="contained"
          disableElevation
          color="secondary"
          >
            See More
        </Button>
      }
      bgcolor="primary.main"
      color="primary.contrastText"
      >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {filteredArticles.map(({ url, image, publication, date }) => (
          <Grid item xs={5} sm={3} columns={15} key={`${publication}-${date}`}>
            <Box px={{ xs: 3, sm: 4, md: 5, lg: 6}} py={{ xs: 2, md: 3}} >
              <Link
                href={url}
                target="_blank"
                aria-label={`Link to article from ${publication}`}
              >
                <Box sx={{
                  backgroundImage: `url(${
                    !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                  })`,
                  paddingTop: '100%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  opacity: 0.7,
                  '&:hover': {
                    opacity: 1,
                  },
                }}>
                </Box>
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
    </SectionComponent>
  )
}

PressClippings.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}


export default () => (
  <StaticQuery
    query={graphql`
      {
        markdownRemark(frontmatter: {templateKey: {eq: "press-page"}}) {
          frontmatter {
            articles {
              date
              headline
              publication
              url
              quote
              featured
              image {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 88, background: "rgba(0,0,0,0)", duotone: { highlight: "#939393", shadow: "#FFFFFF" }) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <PressClippings data={data} />}
  ></StaticQuery>
)
            // childImageSharp {
            //   fluid(maxWidth: 500, quality: 88) {
            //     ...GatsbyImageSharpFluid
            //   }
            // }
