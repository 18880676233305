import React from 'react'
import PropTypes from 'prop-types'

import { Button, Grid, Hidden, Typography } from '@mui/material'
import SectionComponent from './SectionComponent'
import { Link, StaticQuery } from 'gatsby'
import Endorsement from '../endorsements-page/Endorsement'
import { Box } from '@mui/system'

const renderEndorsements = (endorsements) => (
  <Grid container>
    {endorsements.slice(0,6).map((endorsement) => (
      <Grid item xs={4} sm={4} key={endorsement.name}>
        <Endorsement {...endorsement} nameColor="text.white" hideTitle={true} />
      </Grid>
    ))}
  </Grid>
)

function EndorsementSummary({ header, ctaText, selectedEndorsements }) {
  const button = (
    <Button
      component={Link}
      to="/endorsements"
      variant="contained"
      disableElevation>
        {ctaText}
    </Button>
  )

  return (
    <SectionComponent
      borderTop={1}
      >
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4}>
        <Grid item xs={12} md={5} textAlign="center">
          <Typography gutterBottom variant="h3">
            {header}
          </Typography>
          <Hidden mdDown>
            {button}
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          {renderEndorsements(selectedEndorsements)}
          <Hidden mdUp>
            <Box textAlign="center" marginTop={4}>
              {button}
            </Box>
          </Hidden>
        </Grid>
      </Grid>
    </SectionComponent>
  )
}

EndorsementSummary.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default EndorsementSummary
