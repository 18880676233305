import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Button, Grid, Hidden, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Link as GastbyLink, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import SectionComponent from './SectionComponent'
import { kebabCase } from 'lodash'

function IssueSummary({ data: { markdownRemark }, header, enabled, text, ctaText }) {
  if (!enabled) {
    return;
  }

  const filteredIssues = markdownRemark.frontmatter.issues.
    filter(({ showonhomepage, hidden }) => showonhomepage && !hidden )
  const button = (
    <Button
      component={GastbyLink}
      to="/platform"
      variant="contained"
      disableElevation>
        {ctaText}
    </Button>
  )

  return (
    <SectionComponent button={
      <Hidden smUp>{button}</Hidden>
    }>
      <Grid container sx={{ alignItems: 'center' }} rowSpacing={0} columnSpacing={8}>
        <Grid item xs="12" sm="6" >
          <Typography component="h2" variant="h4" textAlign={{ xs: 'center', sm: 'initial' }} gutterBottom>{header}</Typography>
          <Typography paragraph={true} gutterBottom>{text}</Typography>
          <Hidden smDown>
            {button}
          </Hidden>
        </Grid>
        <Grid container item xs="12" sm="6">
          <List sx={{ width: '100%' }}>
            {filteredIssues.map(({ name, description, anchor }) => (
              <ListItemButton key={name} component={GastbyLink} to={`/platform#${anchor || kebabCase(name)}`}>
                <ListItemText
                  primary={name}
                  secondary={description} />
                <ListItemIcon sx={{ minWidth: '25px' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
    </SectionComponent>
  )
}

IssueSummary.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default (params) => (
  <StaticQuery
    query={graphql`
      {
        markdownRemark(frontmatter: {templateKey: {eq: "platform-page"}, issues: {elemMatch: {hidden: {eq: false}}}}) {
          frontmatter {
            issues {
              name
              anchor
              description
              hidden
              showonhomepage
            }
          }
        }
      }
    `}
    render={(data) => <IssueSummary data={data} {...params} />}
  ></StaticQuery>
)
