import { Button, Chip, Hidden, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import {
  GoogleMap,
  KmlLayer, LoadScript
} from '@react-google-maps/api';
import React from 'react';
import SectionComponent from './SectionComponent';

const GOOGLE_MAPS_KEY = 'AIzaSyAhc-4gaEv42Lqp1DWaqFMIjgQqtunEY8M'

const GOOGLE_MAPS_URL =
  "https://www.google.com/maps/d/u/3/kml?forcekml=1&mid=1CK04tVVK12klN8xjgE28Z-xbrYfMg4h_"

const Neighborhoods = ({ neighborhoods }) => {

  const neighborhoodString = neighborhoods.map(nabe => nabe.name).join(" - ")
  const neighborhood = <Typography>{neighborhoodString}</Typography>

  return <Box marginBottom={2}>
    <Hidden smDown>
      <Stack direction="row" justifyContent="center" spacing={1}>
        {neighborhood}
      </Stack>
    </Hidden>
    <Hidden smUp>
      {neighborhood}
    </Hidden>
  </Box>
}

const MapComponent = ({ header, text, neighborhoods }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const mapContainerStyle = isMobile ?
    {
      height: "400px",
      width: "100%"
    } :
    {
      height: "700px",
      width: "100%"
    }

  const zoom = isMobile ? 12 : 13

  return (
    <SectionComponent textAlign="center" header={header}
      button={
        <Button
          href="https://nyassembly.gov/mem/search/"
          target="_blank"
          variant="contained"
          disableElevation
          color="primary"
          >
            Am I In The District?
        </Button>
      }
    >
      <Neighborhoods neighborhoods={neighborhoods}/>

      <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY}>
        <GoogleMap
          id="kml-layer-example"
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          options={{
            mapTypeControl: false,
            fullscreenControl: true,
            streetViewControl: false,
          }}
        >
          <KmlLayer url={GOOGLE_MAPS_URL}
            options={{
              clickable: false,
              suppressInfoWindows: true,
            }} />
        </GoogleMap>
        <Typography gutterBottom variant="overline">{text}</Typography>
      </LoadScript>
    </SectionComponent>
  )
}

export default MapComponent
