import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Typography } from '@mui/material'

const SectionComponent = ({
  header,
  headerVariant = "h3",
  subheader,
  leadingSubheader = false,
  children,
  button,
  headerAlign,
  subheaderAlign,
  ...params
}) => {

  const useChildrenMargin = header || subheader

  return <Box {...params} paddingY={{ xs: 8, sm: 14}}>
    <Container >
      {subheader && leadingSubheader && (
        <Box textAlign={subheaderAlign}>
          <Typography variant="overline" gutterBottom>
            {subheader}
          </Typography>
        </Box>
      )}
      {header && (
        <Box textAlign={headerAlign}>
          <Typography variant={headerVariant} gutterBottom>
            {header}
          </Typography>
        </Box>
      )}
      {subheader && !leadingSubheader && (
        <Box textAlign={subheaderAlign}>
          <Typography variant="overline" gutterBottom>
            {subheader}
          </Typography>
        </Box>
      )}
      <Box marginBottom={useChildrenMargin ? 4 : 0}>
        { children }
      </Box>
      {button && (
        <Box textAlign="center">
          { button }
        </Box>
      )}
    </Container>
  </Box>
}

SectionComponent.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default SectionComponent
